"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue";
const _hoisted_1 = { class: "" };
const _hoisted_2 = {
  key: 0,
  class: "opacity-25"
};
const _hoisted_3 = {
  key: 1,
  class: "ml-1"
};
import { BigNumberInBase } from "@injectivelabs/utils";
import { getExactDecimalsFromNumber } from "@injectivelabs/sdk-ts";
import { UI_DEFAULT_DISPLAY_DECIMALS } from "@/app/utils/constants";
export default /* @__PURE__ */ _defineComponent({
  __name: "Index",
  props: {
    sm: Boolean,
    xs: Boolean,
    flex: Boolean,
    dense: Boolean,
    noGrouping: Boolean,
    useNumberDecimals: Boolean,
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    },
    number: {
      required: true,
      type: Object
    },
    decimals: {
      type: Number,
      default: UI_DEFAULT_DISPLAY_DECIMALS
    },
    roundingMode: {
      type: Number,
      default: BigNumberInBase.ROUND_DOWN
    },
    abbreviationFloor: {
      type: Number,
      default: 0
    }
  },
  setup(__props) {
    const props = __props;
    const slots = useSlots();
    const actualDecimals = computed(
      () => props.useNumberDecimals ? getExactDecimalsFromNumber(props.number.toNumber()) : props.decimals
    );
    const { valueToString: formattedNumberToString } = useBigNumberFormatter(
      computed(() => props.number),
      {
        abbreviationFloor: props.abbreviationFloor,
        decimalPlaces: actualDecimals.value,
        roundingMode: props.roundingMode,
        displayAbsoluteDecimalPlace: true
      }
    );
    const formattedNumber = computed(() => {
      if (props.number.eq(0)) {
        return ["0.00"];
      }
      if (props.noGrouping) {
        return [formattedNumberToString.value];
      }
      const match = formattedNumberToString.value.match(
        /^(-?[\d,]+)((\.)(\d+?\d+?)(0*))?$/
      );
      const groups = !match ? formattedNumberToString.value ? [formattedNumberToString.value] : [] : match[2] ? [`${match[1]}${match[3]}${match[4]}`, match[5]] : [`${match[1]}`];
      return groups;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass({ "flex justify-start items-center gap-1": __props.flex })
      }, [
        _createElementVNode("span", {
          class: _normalizeClass(["font-mono", {
            "text-xs": __props.xs,
            "text-sm": __props.sm,
            "inline-block": !__props.flex,
            "flex items-center": __props.flex
          }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["flex", { "mr-1": _unref(slots).addon }])
          }, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(__props.prefix || "") + _toDisplayString(_unref(formattedNumber)[0]), 1),
            _unref(formattedNumber)[1] ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(formattedNumber)[1] || ""), 1)) : _createCommentVNode("", true),
            __props.suffix ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(__props.suffix || ""), 1)) : _createCommentVNode("", true)
          ], 2)
        ], 2),
        _renderSlot(_ctx.$slots, "addon")
      ], 2);
    };
  }
});
