import { default as __nuxt_component_0 } from "/opt/build/repo/components/App/Button.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "flex h-full w-full items-center justify-center text-center",
  "data-cy": "wallet-not-connected-modal-warning"
};
const _hoisted_2 = {
  key: 0,
  class: "text-center py-4"
};
const _hoisted_3 = { class: "text-xs text-gray-300 w-full" };
const _hoisted_4 = {
  key: 1,
  class: "text-xs text-gray-400 py-4"
};
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "UserNotConnectedNote",
  props: {
    cta: Boolean
  },
  setup(__props) {
    const modalStore = useModalStore();
    function handleConnect() {
      modalStore.openModal({ type: Modal.Connect });
    }
    return (_ctx, _cache) => {
      const _component_AppButton = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [
        __props.cta ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("connect.pleaseConnectToYourWallet")), 1),
          _createVNode(_component_AppButton, {
            class: "bg-blue-500 text-blue-900 font-semibold mt-2",
            "data-cy": "wallet-not-connected-warning-modal-connect-button",
            onClick: handleConnect
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("connect.connect")), 1)
            ]),
            _: 1
          })
        ])) : (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t("connect.pleaseConnectToYourWalletCta")), 1))
      ]);
    };
  }
});
