import { default as __nuxt_component_0 } from "/opt/build/repo/components/Common/UserNotConnectedNote.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export default /* @__PURE__ */ _defineComponent({
  __name: "Wrapper",
  props: {
    breakMd: Boolean
  },
  setup(__props) {
    const walletStore = useWalletStore();
    return (_ctx, _cache) => {
      const _component_CommonUserNotConnectedNote = __nuxt_component_0;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["min-h-orders flex-col h-full overflow-y-auto bg-gray-1000 flex-grow", {
          "md:min-h-0 md:flex": __props.breakMd,
          "min-h-0 flex": !__props.breakMd
        }])
      }, [
        !_unref(walletStore).isUserWalletConnected ? (_openBlock(), _createBlock(_component_CommonUserNotConnectedNote, {
          key: 0,
          cta: "",
          class: "bg-gray-1000"
        })) : _renderSlot(_ctx.$slots, "default", { key: 1 })
      ], 2);
    };
  }
});
